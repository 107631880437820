import { getAPIHandler, getDataHandlerAPI } from "src/ApiConfig/service";
import * as XLSX from "xlsx";
import { PublicKey } from "@solana/web3.js";
import toast from "react-hot-toast";

// TestNet
export const stakingContract = "0x050e0b587908707C267696E5A985b9D7f740b926";
export const rewardContract = "0x4F04eCFb3687e1152E833Cc3E4B4F8Dd413F6306";
// export const bscRedirectUrl = "https://testnet.bscscan.com";
// export const ACTIVE_NETWORK = 97;

// Main-Net
// export const stakingContract = "0x84c3fe3AA2700a7160A0508Ffb040C899C77bf61"; // main-net
// export const rewardContract = "0x0Ae23dB050a0c9811AEf568D48920c7524B504F4"; // main-net
// export const rewardContract = process.env.REACT_APP_DISTRIBUTION; // main-net
// export const stakingContract = process.env.REACT_APP_STAKING; // main-net
//
export const bscRedirectUrl = "https://bscscan.com"; // main-net
export const ACTIVE_NETWORK = 56;

export function sortAddress(add) {
  const sortAdd = `${add.slice(0, 5)}...${add.slice(add.length - 4)}`;
  return sortAdd;
}
export function sortAddress1(add) {
  const sortAdd = `${add.slice(0, 15)}...`;
  return sortAdd;
}
export function formatToComaNumberingSystem(number) {
  const numberString = parseFloat(number).toString();
  const [integerPart, decimalPart] = numberString.split(".");

  let decimalPartValue = decimalPart
    ? decimalPart?.length > 5
      ? decimalPart.slice(0, 4)
      : decimalPart
    : 0;
  // console.log(
  //   decimalPartValue,
  //   " decimalPartValue ------- decimalPart ",
  //   decimalPart
  // );
  const lastThreeDigits = integerPart.slice(-3);
  const otherDigits = integerPart.slice(0, -3);

  const formattedInteger =
    otherDigits.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
    (otherDigits !== "" ? "," : "") +
    lastThreeDigits;

  return decimalPartValue > 0
    ? formattedInteger + "." + decimalPartValue
    : formattedInteger;
}

export const convertArrayToXLSX = async (data, fileName) => {
  if (data.length === 0) {
    toast.error(`No data to export.`);
    return;
  }
  try {
    const wb = XLSX.utils.book_new();
    const sheetData = [];
    // Flatten the data if it's an array of objects
    if (Array.isArray(data[0])) {
      data.forEach((array) => {
        const flattenedArray = array.map((obj) => flattenObject(obj, null, []));
        sheetData.push(...flattenedArray);
      });
    } else {
      sheetData.push(...data.map((obj) => flattenObject(obj, null, [])));
    }
    const ws = XLSX.utils.json_to_sheet(sheetData);
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const s2ab = (s) => {
      const buf = new ArrayBuffer(s.length);
      const view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };

    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
    const downloadURL = URL.createObjectURL(blob);
    const timestamp = new Date().toISOString().replace(/[:.-]/g, "");
    const timestampedFileName = `${fileName}_${timestamp}.xlsx`;
    await downloadFileAsync(downloadURL, timestampedFileName);
  } catch (error) {
    console.error("Error:", error);
  }
};
const toReadableFormat = (key) => {
  if (/^[A-Z]+$/.test(key)) {
    return key;
  }
  return key
    .replace(/([A-Z])/g, " $1") // Add a space before capital letters
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/^\w/, (c) => c.toUpperCase()); // Capitalize the first letter
};

// Utility function to flatten an object with dynamic key mapping
const flattenObject = (obj, parent = "", res = {}) => {
  for (let key in obj) {
    let propName = parent ? parent + " " + key : key;
    if (Array.isArray(obj[key])) {
      obj[key].forEach((item, index) => {
        if (typeof item !== "string") {
          const itemKey = `${toReadableFormat(propName)} ${
            item?.action ? item?.action.toUpperCase() : index
          }`;
          Object.entries(item).forEach(([subKey, value]) => {
            const subPropName = `${toReadableFormat(subKey)}(${itemKey})`;
            res[subPropName] =
              typeof value === "boolean" ? value?.toString() : value;
          });
        } else {
          const itemKey = `${toReadableFormat(propName)} ${
            item?.action ? item?.action.toUpperCase() : index
          }`;
          res[itemKey] = item;
        }
      });
    } else if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
      flattenObject(obj[key], propName, res);
    } else {
      let readableKey = parent ? toReadableFormat(key) : toReadableFormat(key);
      res[readableKey] =
        typeof obj[key] === "boolean" ? obj[key]?.toString() : obj[key];
    }
  }
  return res;
};
async function downloadFileAsync(url, fileName) {
  try {
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.style.display = "none";
    document.body.appendChild(a);

    return new Promise((resolve, reject) => {
      a.click();
      resolve();
    }).then(() => {
      document.body.removeChild(a);
      URL.revokeObjectURL(url); // Clean up
    });
  } catch (error) {
    console.error("Error:", error);
  }
}

export function checkNumber(value) {
  // const re = /^(?!0+$)[0-9]{1,10}$/gm;
  // return re.test(value);
  const re = /^[1-9][0-9]{9}$/;
  return re.test(value);
}
export function replacetext(text, replaceTo, replaceWith) {
  // Replace '-' with ' ' and split the string into words
  const words = text
    .split(replaceTo)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words with space
  return words.join(replaceWith);
}
export const calculateTimeLeft = (endDate) => {
  if (endDate) {
    let difference = +new Date(endDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  } else {
    return false;
  }
};

export const getFolderListDataApi = async (
  source,
  page,
  folderType,
  search
) => {
  try {
    const response = await getAPIHandler({
      endPoint: "listFolder",
      source: source,
      paramsData: {
        folderType: folderType,
        // page: page,
        // limit: "10",
        // sort: "ALPHA",
        // search: search,
      },
    });
    if (response.data.responseCode === 200) {
      return response.data.result;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
};

export function fixDecimal(number) {
  return number ? formatToComaNumberingSystem(number) : 0;
}

export function fixDecimalType(number, type) {
  return number ? formatToComaNumberingSystem(number) + " " + type : 0;
}
export function countZerosAfterDecimal(number) {
  const numString = number.toString();
  const decimalIndex = numString.indexOf(".");
  if (decimalIndex === -1) {
    return 0;
  }

  let zeroCount = 0;
  for (let i = decimalIndex + 1; i < numString.length; i++) {
    if (numString[i] === "0") {
      zeroCount++;
    } else {
      break;
    }
  }
  return zeroCount;
}

export const formatKey = (key) => {
  return key
    .replace(/([A-Z])/g, " $1") // Add space before capital letters
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
};
export const getBase64 = (file, cb) => {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (err) {
    console.log("Error: ", err);
  };
};

export function generateRandomName() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const nameLength = Math.floor(Math.random() * (8 - 5 + 1)) + 3;
  let randomName = "";

  for (let i = 0; i < nameLength; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    const character = characters.charAt(randomIndex);

    if (i === 0) {
      randomName += character.toUpperCase();
    } else {
      randomName += character;
    }
  }

  return randomName;
}

export const downloadExcel = (dataToPrint, sheetName) => {
  const workSheet = XLSX.utils.json_to_sheet(dataToPrint);
  const workBook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workBook, workSheet, sheetName);
  let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
  XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
  XLSX.writeFile(workBook, `${sheetName}.xlsx`);
};

export const listUserHandlerExcel = async ({ paramsData, endPoint }) => {
  try {
    const res = await getDataHandlerAPI({
      endPoint: endPoint,
      paramsData: {
        page: 1,
        ...paramsData,
      },
    });
    if (res.data.responseCode === 200) {
      return res.data.result.docs;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

export const validateAccountAddress = async (account) => {
  try {
    new PublicKey(account);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const handleNegativeValue = (event) => {
  const {
    key,
    target: { value, selectionStart },
  } = event;
  const newValue =
    value.slice(0, selectionStart) + value.slice(selectionStart + 1);
  const parsedValue = parseFloat(newValue);
  if (
    ["ArrowUp", "ArrowDown", "-"].includes(key) &&
    (isNaN(parsedValue) || parsedValue < 0)
  ) {
    event.preventDefault();
  }
};

export const routeGroups = {
  accountRoutes: ["/account", "/edit-user-profile", "/change-user-password"],
  subAdminRoutes: ["/sub-admin-management", "/add-sub-admin"],
  userRoutes: ["/user-management", "/user-details"],
  kycRoutes: ["/kyc-management", "/view-kyc-details"],
  contuctUsRoutes: ["/ticket-management", "/pending-ticket"],
  staticRoutes: [
    "/static-management",
    "/view-static-content",
    "/edit-static-content",
    "/view-faq-content",
    "/add-faq",
    "/team-management",
    "/add-team",
    "/blog-management",
    "/add-blog",
  ],
  rewardRoutes: ["/reward-management", "/reward-settings"],
};

export const getUpdatedRoutes = (matchedArray, routeGroups) => {
  const existingRoutes = new Set(matchedArray);
  for (const routes of Object.values(routeGroups)) {
    const isGroupMatched = routes.some((route) => matchedArray.includes(route));
    if (isGroupMatched) {
      routes.forEach((route) => {
        existingRoutes.add(route);
      });
    }
  }
  return Array.from(existingRoutes);
};

export const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left",
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left",
  },
  elevation: 0,
  PaperProps: {
    style: {
      top: "0px !important",
      maxHeight: 250,
    },
  },
};
